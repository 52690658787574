.transfer-pension-insurance-information-summary {
  .pensions {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .lysa-ui-snackbar {
    justify-content: flex-start;
    text-align: start;
  }

  .warning {
    color: var(--lysa-state-error);
  }

  .next {
    margin-top: 4rem;
  }

  .move-cards {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .move-cards-header {
    margin: 4rem 0 0 0;
  }

  .error-container {
    margin-top: 24px;
  }

  .movable-insurance-fieldset {
    border: none;
    padding: 0;
    margin: 0;
  }
}
