.glidepath-graph {
  .wrapper {
    width: 100%;
  }
  .footer {
    margin-top: 0.8rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .dot {
    height: 1.2rem;
    width: 1.2rem;
    border-radius: 50%;
    display: inline-block;
    margin-right: 0.8rem;
  }

  .right {
    background-color: #e6e9f4;
  }

  .left {
    background-color: #1840e3;
  }
}
