.transfer-pension-docs {
  .link-container {
    display: flex;
    flex-direction: column;

    .lysa-link-button {
      text-align: left;
    }
  }
}
