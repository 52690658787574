.transfer-pension-fees {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  dl {
    margin-top: 0;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    gap: 16px;

    dt,
    dd {
      margin: 0;
      width: auto;
    }

    dd {
      font-weight: bold;
      display: flex;
      align-items: center;
    }
  }
  .transfer-pension-fees__snackbar {
    text-align: left;
  }

  .total {
    font-weight: bold;
  }
}
