.PendingPensions {
  &__moves {
    margin-bottom: 24px;
  }

  &__steps-header {
    margin-bottom: 12px;
  }

  &__steps-notification {
    text-align: left; // TODO_PEN: Move option to ui-2?
  }
}
