.MailingInstructions {
  margin-bottom: 24px;

  &__buttons {
    margin-bottom: 20px;
  }

  &__sent-home {
    text-align: left; // TODO_PEN: Move option to ui-2?
  }

  &__address {
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-radius: var(--lysa-card-border-radius);
    background-color: #f1f1f1; // TODO_PEN: Color does not exist in ui-2, add or change?
  }
}
