.MoveSteps {
  width: 100%;

  &__info {
    display: flex;
    align-items: center;
    padding: 18px 22px;
    gap: 12px;
    border-radius: var(--lysa-card-border-radius);
    background: var(--lysa-background-card-gray-box);
  }
}
