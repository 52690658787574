.transfer-pension-story-advice {
  .type-card {
    dl {
      display: flex;
      justify-content: space-between;
    }

    dt {
      font-weight: bold;
    }

    dd {
      display: flex;
      align-items: center;

      .type-text {
        margin-right: 8px;
      }
    }
  }

  .disclaimer {
    margin-top: 32px;
  }

  .payout-section {
    margin: 2rem 0;
  }

  .payout-card {
    box-shadow: none;
    border: var(--lysa-card-border-size) solid var(--lysa-card-border);
    margin-bottom: 0;
  }

  .action-buttons {
    margin-top: 2rem;
  }
}
