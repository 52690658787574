.transfer-pension-collection-methods {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  /* Override card-list default style to align with design for this use case */

  .card-list {
    margin: 0;
  }

  .insurance-company-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.6rem 2rem;
  }

  .icon-circle {
    display: grid;
    place-items: center;
    height: 48px;
    width: 48px;
    min-width: 48px;
    min-height: 48px;
    border-radius: 100%;
    background-color: var(--color-gray-50);
  }

  .insurance-company {
    display: flex;
    align-items: center;
    gap: 2rem;
  }

  .insurance-company-logo {
    flex-shrink: 0;
  }

  .primary-button {
    margin-top: 2rem;
  }

  .insurance-company-error-text,
  .warning {
    color: var(--lysa-state-error);
    margin-bottom: 0;
  }

  .error-container {
    margin-top: 24px;
  }

  .insurance-company-list-fieldset {
    border: none;
    padding: 0;
    margin: 0;
  }

  /*
    Overide Lysa default style.
  */

  .lysa-ui-input-group {
    margin-bottom: 0 !important;
  }

  .lysa-ui-input-group label {
    background-color: transparent !important;
    padding: 2rem !important;
  }
}
