@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";

.pension-logo {
  color: var(--lysa-text-white);
  background-color: var(--lysa-icon-primary);
  border-radius: 50%;
  display: grid;
  place-items: center;
  position: relative;
  text-align: center;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  font-weight: bold;
  letter-spacing: -1px;
}

.avanza {
  background-color: hsla(147, 40%, 52%, 1);
}

.folksam {
  background-color: hsla(198, 100%, 44%, 1);
}

.futur {
  background-color: hsla(241, 98%, 50%, 1);
}

.handelsbanken {
  background-color: hsla(204, 100%, 30%, 1);
}

.lansforsakringar {
  background-color: hsla(206, 100%, 31%, 1);
}

.movestic {
  background-color: hsla(339, 100%, 42%, 1);
}

.nordea {
  background-color: hsla(240, 100%, 18%, 1);
}

.nordnet {
  background-color: hsla(0, 0%, 0%, 1);
}

.seb {
  background-color: hsla(95, 62%, 48%, 1);
}

.skandia {
  background-color: hsla(161, 39%, 40%, 1);
}

.spp {
  background-color: hsla(4, 77%, 48%, 1);
}

.swedbank {
  background-color: hsla(22, 100%, 50%, 1);
}
