.transfer-pension-intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .list-item {
    display: flex;
    align-items: center;
    gap: 2rem;
    margin: 2rem 0;
    text-align: left;
  }

  .icon-circle {
    display: grid;
    place-items: center;
    height: 48px;
    width: 48px;
    min-width: 48px;
    min-height: 48px;
    border-radius: 100%;
    background-color: var(--color-gray-50);
  }

  .calculation-information {
    text-align: left;
    align-self: flex-start;
  }

  .read-more {
    margin: 1rem 0 2rem 0;
  }

  .next {
    margin-top: 3rem;
  }
}
