.transfer-pension-insurance-signing-list {
  --angle: 0deg;

  .list {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .list-item {
    padding: 1.6rem 2rem;
    background-color: var(--color-gray-50);
    border-radius: 1rem;
    border: 2px solid transparent;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    animation: rotate 6s linear infinite;
  }

  .pending {
    /* Paint an image in the border */
    border-image: conic-gradient(
        from var(--angle),
        var(--color-blue-700) 0deg 90deg,
        var(--color-blue-100) 90deg 180deg,
        var(--color-blue-500) 180deg 270deg,
        var(--color-blue-100) 270deg 360deg
      )
      1;
  }

  .list-item-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.6rem;

    .list-item-header-left {
      display: flex;
      align-items: center;
      gap: 1.6rem;

      .list-item-header-left__pension-logo {
        flex-shrink: 0;
      }
    }

    p {
      margin: 0;
      padding: 0;
    }
  }

  .container {
    margin: 1rem 0;
    display: grid;
    place-items: center;
    gap: 0.8rem;
  }

  .actions {
    margin-top: 1rem;
  }

  .qr-container {
    margin-top: 1rem;
    display: grid;
    place-items: center;

    .qr-wrapper {
      width: auto;
      background-color: var(--lysa-background-card);
      padding: 0.2rem;
      display: grid;
      place-items: center;
    }
  }

  hr {
    height: 1px;
    background-color: var(--lysa-border-black);
    opacity: 0.1;
    border: none;
    margin: 1.4rem 2rem 0 2rem;
  }

  .qr-instructions {
    margin-top: 2rem;
    list-style: decimal;
    font-size: small;

    & > li {
      margin-bottom: 10px;
    }

    & > li:last-child {
      margin-bottom: 0;
    }
  }

  .error-container {
    margin-bottom: 2rem;
  }

  .next {
    margin-top: 6rem;
  }
}

@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

@keyframes rotate {
  to {
    --angle: 360deg;
  }
}
