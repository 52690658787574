.transfer-pension-bank-id {
  .pensions {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0 0 10rem 0;
  }

  .pension {
    padding: 2rem;
    background-color: var(--color-gray-50);
    border-radius: 12px;
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .companies {
    display: flex;
    flex-direction: column;
  }

  .insuranceInstitute,
  .employer {
    padding: 0;
    margin: 0;
  }

  .insuranceInstitute,
  .employer-tin {
    font-weight: bold;
  }

  .cta {
    margin-top: 10rem;
  }
}
