.transfer-pension-risk-deviation {
  .glide-path {
    margin: 3rem 0 1rem 0;
  }

  .slider-label {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .slider-scale {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -1rem;
    margin-bottom: 4rem;
  }

  .list {
    margin: 1rem 0 3rem 1.8rem;
    list-style: initial;
  }
  .list li {
    margin: 1.4rem 0;
  }
}
