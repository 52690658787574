.transfer-pension-snail-mail {
  .need-action {
    color: #ff7576;
    font-size: 12px;
    margin-top: 0;
  }

  .pensions {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 2rem;
  }
}
