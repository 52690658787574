.transfer-pension-insurance-info {
  .heading {
    margin-bottom: 2rem;
  }

  .lysa-ui-radio-card-group-card {
    margin-bottom: 2rem !important;
  }

  .snackbar-link {
    display: inline-flex;
    align-items: center;
  }

  .find-company {
    margin-top: -1.8rem;
  }

  .find-company-link {
    font-weight: bold;
  }

  .radio-group-card-label {
    line-height: 20px;
    font-size: 14px;
    margin-bottom: 8px;
  }
}
