.potential-move-card {
  background-color: #f1f1f1;
  padding: 2rem;
  border-radius: 1rem;

  .potential-move-card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .potential-move-card-header-company {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.4rem;
  }

  .potential-move-card-header-company-name-and-worth {
    display: flex;
    flex-direction: column;
  }

  .potential-move-card-institute {
    font-weight: 700;
    font-size: medium;
  }

  .potential-move-card-worth {
    color: var(--lysa-text-secondary);
  }

  .information-list {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .information {
    color: var(--lysa-text-secondary);
    display: flex;
    align-items: flex-start;
    gap: 2rem;
  }

  .information-message {
    margin: 0.4rem 0;
  }

  .icon {
    flex: 0 0 24px;
  }

  hr {
    height: 1px;
    background-color: black;
    opacity: 0.15;
    border: none;
    margin: 1.6rem 0 1.2rem 0;
  }

  dl {
    display: flex;
    justify-content: space-between;

    dd {
      font-weight: bold;
      text-align: end;
    }
  }
}
