@use "~@lysaab/ui-2/components/styling/variables";
@use "~@lysaab/ui-2/components/styling/media";
@use "../styling/layout";

.page > .page-footer {
  margin: 0 -16px;
  padding: 0 16px;
  background-color: var(--lysa-background-canvas);
  min-height: 100px;

  @include media.landscape {
    margin: 0 -24px;
    padding: 0 24px;
  }

  @include media.desktop {
    min-height: layout.$footer-height-desktop;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0;
  }

  li {
    margin: 10px 0;
  }

  a,
  button {
    color: var(--lysa-text-primary);
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
    }
  }

  hr {
    border: 0;
    border-top: 1px solid #eaeaea;
    margin: 0 -16px;

    @include media.landscape {
      margin: 0 -24px;
    }
  }

  .footer-legal {
    padding: 40px 0;
    max-width: variables.$desktop-plus;
    margin: 0 auto;

    button.footer-legal-link-button {
      display: inline-block;
      padding: 0;
      background-color: transparent;
      border: none;
      font: inherit;
      cursor: pointer;
    }

    @include media.desktop {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    & > ul {
      @include media.desktop {
        display: flex;
      }
    }

    li {
      @include media.desktop {
        margin: 0 20px 0 0;
      }
    }
  }

  .footer-disclaimer {
    font-size: 1.2rem;
    padding: 40px 0;
    max-width: variables.$desktop-plus;
    margin: 0 auto;
  }

  .footer-social {
    display: flex;
    margin: 34px 0 0 -6px; // due to link padding

    @include media.desktop {
      margin: 0 -6px 0 0;
    }

    a {
      padding: 6px;

      svg {
        .icon-primary {
          fill: var(--lysa-icon-primary);
        }
      }
    }
  }

  .see-no-evil-wrapper {
    display: flex;
    justify-content: flex-end;
  }
}
